<template>
  <div class="home">
    <div id="banner" class="block text-center">
      <video src="../assets/img/banner.mp4" loop autoplay></video>
    </div>
    <div class="solution">
      <h3 class="solution-title">技术解决方案</h3>
      <div class="solution-cont"><img src="../assets/img/jjfa.jpg" style="" /></div>
      <div class="solution-cont"><img src="../assets/img/jjfa2.jpg" style="" /></div>
      <div class="solution-cont"><img src="../assets/img/jjfa3.jpg" style="" /></div>
      <div class="solution-cont"><img src="../assets/img/jjfa4.jpg" style="" /></div>
    </div>
    <div class="application">
      <h3 class="application-title">应用场景</h3>
      <div class="application-cont">
        <img src="../assets/img/application1.jpg" style="" />
        <img src="../assets/img/application2.png" style="" />
        <img src="../assets/img/application3.jpg" style="" />
        <img src="../assets/img/application4.jpg" style="" />
        <img src="../assets/img/application5.jpg" style="" />
        <img src="../assets/img/application6.jpg" style="" />
      </div>
    </div>
    <div class="application">
      <h3 class="application-title">为什么选择我们</h3>
      <div class="application-cont">
        <img src="../assets/img/why1.jpg" style="" />
        <img src="../assets/img/why2.jpg" style="" />
        <img src="../assets/img/why3.jpg" style="" />
        <img src="../assets/img/why4.jpg" style="" />
        <img src="../assets/img/why5.jpg" style="" />
        <img src="../assets/img/why6.jpg" style="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>
<style scoped>
#banner{ width: calc(100vw - 18px);; height: 100vh; }
#banner video{ object-fit: cover;width: 100%; height: 100%;}
#banner img{ width: 100%; height: 100%; object-fit:cover;}
.home .solution-title{ font-size: 28px; text-align: center;}
.home .solution-cont{ width: 1200px; height: 600px; margin: 20px auto 0 auto;}
.home .solution-cont img{width: 100%; height: 100%; object-fit:cover;}
.home .application-title{ font-size: 28px; text-align: center;}
.home .application-cont{ width: 1200px; margin:0 auto; display: flex; flex-wrap: wrap; gap: 20px; justify-content: space-between;}
.home .application-cont img{width: 49%; object-fit: cover;}
</style>
