<template>
  <footer>
    <div class="cont">
      <div class="address">
        <h3 class="title">公司地址与联系方式</h3>
        <ul>
          <li>艾奇总部： 泉州市丰泽区体育街华创园D509室</li>
          <li>联系电话： 0595-22770778</li>
          <li>艾奇深圳： 南山区留先大道4168号众冠时代广场A座3108-3111</li>
          <li>联系电话： 0595-22770778</li>
        </ul>
      </div>
      <div class="email">
        <h3 class="title">公司邮箱</h3>
        <ul>
          <li>招聘： hr@eiker.com.cn</li>
          <li>业务咨询： Zhangzifang@eiker.com.cn</li>
          <li>艾奇深圳： 南山区留先大道4168号众冠时代广场A座3108-3111</li>
          <li>技术服务： lcheer@eiker.com.cn</li>
        </ul>
      </div>
      <div class="kefu"><img  class="kefu" src="../assets/img/qrcode/艾奇客服.jpg" alt=""></div>
      <div class="gongzhonghao"><img class="gongzhonghao" src="../assets/img/qrcode/艾奇公众号.jpg" alt=""></div>
      <div class="about">
        <h3 class="title">关于我们</h3>
        <ul>
          <li>易客创新.艾奇科技</li>
          <li>泉州.深圳.上海.苏州</li>
          <li>懂电路、懂软件、懂外观、懂结构</li>
          <li>隐私声明</li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<style>
footer{ background-color: #f7f7f7;}
.cont{ margin: 0 auto; width: 1200px; text-align: left; padding-top: 50px; font-size: 12px; display: flex; justify-content: space-between;}
.address{ width: 350px;}
.email{ width: 350px;}
.about{ width: 200px;}
.kefu{ width: 95px; height: 95px; margin-top: 10px;}
.gongzhonghao{ width: 97px; height: 95px; margin-top: 10px;}
ul, li{ list-style: none; margin:0; padding: 0;}
.address ul li, .email ul li, .about ul li{ height: 22px; line-height: 22px;}
</style>
