<template>
  <div id="wrap">
    <div class="logo"><img src="./assets/img/logo.png" alt="艾奇"/></div>
    <ul id="nav">
      <li><a href="/home">首页</a></li>
      <li><a href="/solution">解决方案</a></li>
      <li><a href="/design">工业设计</a></li>
      <li><a href="/service">ODS服务</a></li>
      <li><a href="/consult">合作咨询</a></li>
      <li><a href="/about">关于艾奇</a></li>
      <li><a href="/download">资料下载</a></li>
    </ul>
    <router-view/>
  <Footer />
  </div>
</template>
<script>
import Footer from '@/components/footer.vue'

// Import Swiper styles
export default {
  components: { Footer },
  setup () {
  }
}
</script>
<style>
body{ background: url('../src/assets/img/dw.jpg') repeat;}
.logo{ width: 200PX; height: 60px; font-size: 26px; color: white; position: absolute; left: 100px; top: 20px; z-index: 100;}
.logo img{ width: 100%; height: 100%; object-fit: contain;}
body, ul, li, html, #app{ list-style: none; margin: 0; padding: 0;}
#wrap {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: calc(100vw - 28px);
}
#banner{ width: calc(100vw - 18px);; height: 100vh;}
#banner img{ width: 100%; height: 100%; object-fit:cover;}
#nav {
  width: 700px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 30px;
  right: 100px;
  z-index: 100;
}
#nav a{ color: white;font-weight: bold; text-decoration: none;}
/deep/ .el-carousel__indicators--horizontal{ bottom: 50px;}
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
